import type { EmbedAsset } from '@/graphql/embed'
import type { ImageAsset } from '@/graphql/image'
import type { VideoAsset } from '@/graphql/video'
import type { MaybeArray } from '@/types/maybe-array'
import { isArray } from 'lodash-es'
import { isNullOrUndefined } from './is-null-or-undefined'

export function isCraftImage(item: any): item is MaybeArray<ImageAsset> {
	item = isArray(item) ? item[0] : item

	if (isNullOrUndefined(item)) return false

	return 'type' in item ? item.type.startsWith('image') : false
}

export function isCraftVideo(item: any): item is MaybeArray<VideoAsset> {
	item = isArray(item) ? item[0] : item

	if (isNullOrUndefined(item)) return false

	return 'type' in item ? item.type.startsWith('video') : false
}

export function isCraftEmbed(item: any): item is MaybeArray<EmbedAsset> {
	item = isArray(item) ? item[0] : item

	if (isNullOrUndefined(item)) return false

	return 'type' in item ? item.type === 'application/json' : false
}
